<template>
	<div class="card" :class="$style.container">
		<div class="text-dark font-size-24 mb-3">
			<strong>Ingresa a tu cuenta</strong>
		</div>
		<a-form class="mb-4" :form="form" @submit="handleSubmit">
			<a-form-item>
				<a-input size="large" autocomplete="off" placeholder="Email" v-decorator="['email', { initialValue: '', rules: [{ required: true, message: 'Ingresa tu email' }] }]" />
			</a-form-item>
			<a-form-item>
				<a-input
					size="large"
					autocomplete="off"
					placeholder="Password"
					type="password"
					v-decorator="['password', { initialValue: '', rules: [{ required: true, message: 'Ingresa tu contraseña' }] }]"
				/>
			</a-form-item>
			<a-button type="primary" htmlType="submit" size="large" class="text-center w-100" :loading="loading">
				<strong>Ingresa</strong>
			</a-button>
		</a-form>
		<!-- <router-link to="/auth/forgot-password" class="kit__utils__link font-size-16">¿Olvidaste tu contraseña?</router-link> -->
	</div>
</template>
<script>
import { mapState } from 'vuex'

export default {
	name: 'CuiLogin',
	computed: {
		...mapState(['settings']),
		loading() {
			return this.$store.state.user.loading
		},
	},
	data: function () {
		return {
			form: this.$form.createForm(this),
		}
	},
	methods: {
		changeAuthProvider(value) {
			this.$store.commit('CHANGE_SETTING', { setting: 'authProvider', value })
		},
		handleSubmit(e) {
			e.preventDefault()
			this.form.validateFields((err, values) => {
				if (!err) {
					this.$store.dispatch('user/LOGIN', { payload: values })
				}
			})
		},
	},
}
</script>
<style lang="scss" module>
@import '@/components/cleanui/system/Auth/style.module.scss';
</style>
